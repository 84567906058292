import React from 'react';
import { useLayoutEffect } from "react";

export const Video = ({ url, speed }) => {
  useLayoutEffect(() => {
    const video = document.getElementById("movie");
    video.playbackRate = speed;
  }, []);
  return <video src={url} muted loop autoPlay={true} id="movie" />;
};

// export default Video;