import React from "react";
// import React, { useState } from 'react'
// import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap'
// import { NavLink as RouteLink } from 'react-router-dom'

const Navigation = () => {
  return (
    <nav className="navbar">
      <div className="author">
        <a href="#">
          <span>Jun Mian Chen</span>
        </a>
      </div>
      <div className="toggle-button">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div className="navbar-links">
        {/* add usetate, add boolean, class conditional on state. If open state is true, then active state  */}
        {/* conditional classes */}
        {/* active (open variable) variable a state instead of a prop */}
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

// const Navigation = () => {
//     const [isOpen, setIsOpen] = useState(false)
//     const toggle = () => setIsOpen(!isOpen)

//     return (
//         <Navbar dark color="dark" expand="md" fixed="top">
//             <Container>
//             <NavbarBrand href="">Jun Mian Chen</NavbarBrand>
//             <NavbarToggler onClick={toggle} />
//             <Collapse isOpen={isOpen} navbar>
//                 <Nav className="ml-auto" navbar>
//                     <NavItem>
//                         <NavLink tag={RouteLink} to="#">Home</NavLink>
//                     </NavItem>
//                     <NavItem>
//                         <NavLink tag={RouteLink} to="#about">About Me</NavLink>
//                     </NavItem>
//                     <NavItem>
//                         <NavLink tag={RouteLink} to="#projects">Projects</NavLink>
//                     </NavItem>
//                     <NavItem>
//                        <NavLink tag={RouteLink} to="#contact">Contact</NavLink>
//                     </NavItem>
//                     <NavItem>
//                         <NavLink tag={RouteLink} to="#submissions">Submissions</NavLink>
//                     </NavItem>
//                 </Nav>
//             </Collapse>
//             </Container>
//         </Navbar>
//     )
// }

export default Navigation;
