import React from "react";
// import { Container, Row, Col, Button, CardBody, CardTitle, CardText, CardFooter, Card } from 'reactstrap'
import abstract from "../../assets/abstract.mp4";
import outerSpace from "../../assets/outerSpace.mp4";
import showcaselight from "../../assets/showcase-light.mp4";
import { Video } from "./Video";
// import { audio } from "../../assets/audio.mp3";
// import { Audio } from "./Audio";

const Home = () => {
  return (
    <section className="showcase" id="home">
      {/* <video src={abstract} autoplay="true" muted loop></video> */}
      {/* <video src={showcaselight} autoplay="true" muted loop></video> */}
      <VideoSpeed />
      <div className="overlay"></div>
      {/* Keep, disallows vid opts */}
      {/* <AuthorName /> */}
      {/* <Audio /> */}
      <AuthorTitle />
    </section>
  );
};

// function AuthorName() {
//   return (
//     <div className="hometext">
//       <h2 className="glow center">Jun Mian Chen</h2>
//       <br></br>
//       <AuthorTitle />
//       <br></br>
//       <LiquidAboutMe />
//     </div>
//   );
// }

function AuthorTitle() {
  return (
    <div>
<h1>
        <span>L</span>
        <span>o</span>
        <span>o</span>
        <span>k</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
        <span>&nbsp;</span>
        <span>f</span>
        <span>o</span>
        <span>r</span>
        <span>&nbsp;</span>
        <span>s</span>
        <span>o</span>
        <span>m</span>
        <span>e</span>
        <span>t</span>
        <span>h</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
        <span>?</span>
        {/* <span className="showEffect">😈</span> */}
      </h1>

      {/* <h2 className="subText"><span>Ask me for my portfolio😉</span></h2> */}
      {/* <h1>
        <span>&lt;</span>
        <span> C</span>
        <span>o</span>
        <span>m</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
        <span>&nbsp;</span>
        <span>s</span>
        <span>o</span>
        <span>o</span>
        <span>n</span>
        <span>.</span>
        <span>.</span>
        <span>. </span>
        <span>&#47;&gt;</span>
      </h1> */}
    </div>
  );
}

function LiquidAboutMe() {
  return (
    <a href="#about">
      <span>About Me</span>
      <div className="liquid"></div>
    </a>
  );
}

function VideoSpeed() {
  return (
    <div>
      <Video url={abstract} speed={0.6} />
      {/* <Video url={outerSpace}  speed={1}/> */}
      {/* <Video url={showcaselight} speed={0.8} /> */}
    </div>
  );
}

// function Audio() {
//   return (
//     <div>
//       <src url={audio} />
//     </div>
//   );
// }

export default Home;