import React from "react";
import "./App.css";
import "./About.css";
import Navigation from "./components/shared/Navigation";
import Footer from "./components/shared/Footer";
import { Fragment, BrowserRouter, Link, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import GoToTop from "./components/shared/GoToTop";
import Projects from "./components/pages/Projects";
import Toggle from "./components/shared/Toggle";

function App() {
  return (
    <main>
      {/* <BrowserRouter>
       
      </BrowserRouter> */}
      {/* <Toggle /> */}

      {/* <Link to={"/login"} className="nav-link">
              Login
      </Link> */}
      <div>
        <Switch>
          <Route path="/">
            {/* <Navigation /> */}
            <Home />
            {/* <About />
            <Projects />
            <Contact />
            <GoToTop />
            <Footer /> */}
          </Route>
        </Switch>
      </div>
    </main>
  );
}

// function App() {
//   return (
//     <main>
//       <BrowserRouter>
//         <Navigation />
//       </BrowserRouter>
//       {/* <Toggle /> */}
//       <Home />
//       <About />
//       <Projects />
//       <Contact />

//       <GoToTop />
//       <Footer />
//     </main>
//   );
// }

// -------- For a multi-page site ----------- \\
// function App() {
//   return (
//    <BrowserRouter>
//         <Navigation />
//         <Switch>
//           <Route exact path="/" component={Home} />
//           <Route exact path="/about" component={About} />
//           <Route exact path="/contact" component={Contact} />
//           <Route exact path="/login" component={Login} />
//           <PrivateRoute path="/submissions">
//             <Listing />
//           </PrivateRoute>
//         </Switch>
//         <Footer />
//     </BrowserRouter>
//   )
// }

export default App;
